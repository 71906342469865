
import { Component, Vue } from 'vue-property-decorator';
import TextPage from '../../components/Layout/TextPage.vue';
import PromoBanner from '../../components/PromoBanner.vue';
import Breadcrumbs from '@/components/Layout/Breadcrumbs.vue';
import { legalData } from '@/constants/legalData';


@Component({
  components: { TextPage, PromoBanner, Breadcrumbs },
  computed: {
    legal() {
      return legalData;
    }
  }
})
export default class UserAgreement extends Vue {
  created() {
    this.$setPageTitle('Пользовательское соглашение | DOTALOOT');
  }
}
